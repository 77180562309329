<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">企业管理</span>
        <img src="../../../assets/right.png"
             alt />
        <span class="center">企业门店</span>
      </div>
    </div>
    <div style="display: flex; align-items: end;justify-content: space-between; margin:10px 0;">
      <div style="display: flex; align-items: center;flex-wrap: wrap;">
        <div>
          <span class="seach_text">用工企业名称：</span>
          <el-select remote
                     reserve-keyword
                     :remote-method="query"
                     @change="getCity"
                     v-model="formInline.workEnterpriseCode"
                     clearable
                     filterable
                     placeholder="请输入企业名称"
                     size="mini"
                     style="margin-right:10px">
            <el-option v-for="item in eList"
                       :key="item.code"
                       :label="item.name"
                       :value="item.code"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">企业状态：</span>
          <el-select size="mini"
                     v-model="formInline.enable"
                     placeholder="请选择"
                     clearable
                     style="margin-right: 10px">
            <el-option :label="item.label"
                       :value="item.value"
                       v-for="(item, i) in stateSelect"
                       :key="i"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">企业区域：</span>
          <el-select v-model="formInline.city"
                     filterable
                     size="mini"
                     @change="getStore"
                     clearable
                     style="margin-right: 10px;width: 200px"
                     :placeholder="'请输入企业区域'">
            <el-option-group v-for="group in areaSelectList"
                             :key="group.provinceName"
                             :label="group.provinceName">
              <span style="color:red"
                    slot="label">{{ group.provinceName }}</span>
              <el-option v-for="item in group.citys"
                         :key="item.code"
                         :label="item.cityName"
                         :value="item.code"></el-option>
            </el-option-group>
          </el-select>
        </div>

        <div>
          <span class="seach_text">门店名称：</span>
          <el-select remote
                     reserve-keyword
                     v-model="formInline.name"
                     clearable
                     filterable
                     placeholder="请输入门店名称"
                     size="mini"
                     style="margin-right:10px">
            <el-option v-for="item in storeList"
                       :key="item.code"
                       :label="item.name"
                       :value="item.name"></el-option>
          </el-select>
          <!-- <el-input placeholder="门店名称" v-model="formInline.name" size="mini" style="margin-right: 10px;width: 200px"
          prefix-icon="el-icon-search" clearable></el-input>-->
        </div>
        <div>
          <span class="seach_text">联系方式：</span>
          <el-input placeholder="联系方式"
                    v-model="formInline.mobile"
                    size="mini"
                    style="margin-right: 10px;width: 200px"
                    prefix-icon="el-icon-search"
                    clearable></el-input>
        </div>

        <el-button size="small"
                   style="margin:10px 10px;border-radius: 5px;"
                   type="primary"
                   @click="seach">查询</el-button>
      </div>
      <div>
        <el-button size="small"
                   style="margin:0 10px;border-radius: 5px;"
                   type="primary"
                   @click="addEnterpriseArea">新增门店</el-button>
      </div>
    </div>

    <el-table :data="list"
              style="width: 100%">
      <el-table-column label="企业名称 "
                       width="280"
                       show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.work_enterprise.name }}</template>
      </el-table-column>

      <el-table-column label="企业区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.area.provinceName }}-{{ scope.row.area.cityName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店编号">
        <template slot-scope="scope">
          <span>{{ scope.row.no }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店名称">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店地址">
        <template slot-scope="scope">
          <span>{{ scope.row.address }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系方式">
        <template slot-scope="scope">
          <span>{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店备注">
        <template slot-scope="scope">
          <span>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column label="企业状态"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: #333333"
                v-if="scope.row.work_enterprise.enable">启用</span>
          <span style="color: #c2c2c2"
                v-if="!scope.row.work_enterprise.enable">禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="shuxian">
            <span class="clickColor"
                  @click="goEdit(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
          </div>

          <div class="shuxian">
            <el-popover placement="top"
                        width="400"
                        trigger="manual"
                        :value="visible"
                        :ref="'popover-' + scope.row.id">
              <div class="del-pop">
                <img src="../../../assets/del.png"
                     alt />
              </div>
              <div class="del-title">删除企业门店信息</div>
              <div class="del-sumtitle">确认删除该条企业门店信息？</div>
              <div class="del-btn">
                <el-button type="primary"
                           @click="del(scope.row)"
                           plain>确认</el-button>
                <el-button type="info"
                           @click="doCancel(scope.row.id)"
                           plain>取消</el-button>
              </div>
              <el-button slot="reference"
                         style="color:red"
                         type="text"
                         @click="pOpen(scope.row.id)">删除</el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor"
            style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total"></el-pagination>
    </div>
    <addDialog :dialogVisible.sync="addDialogVisible"
               :code.sync="code"
               :title.sync="title"
               @func="addClose()"></addDialog>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const request = api('/enterprise');

//门店列表
export const workEnterpriseStoreList = api()('/work_enterprise_store.list.json');
// 企业列表
export const workEnterpriseList = api()('work_enterprise.list.json');
// 区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
// 删除区域
export const workEnterpriseStoreDel = api()('work_enterprise_store.del.json');
// 市区接口
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');

export default {
  components: {
    addDialog: () => import('./add'),
  },
  data () {
    return {
      visible: false,
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      code: '',
      formInline: {
        workEnterpriseCode: '',
        city: '',
        name: '',
        enable: '',
        mobile: ''
      },
      list: [],
      eList: [],
      authenticateState: '',
      addDialogVisible: false,
      title: '',
      // "0"：未认证 "1"：打款中  "2"：打款成功  "3"：认证成功 "4"认证失败
      stateSelect: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '启用',
          value: '1',
        },
        {
          label: '禁用',
          value: '0',
        },
      ],
      storeList: [],
      areaSelectList: [{
        label: '全部',
        value: '',
      },
      {
        label: '北京',
        value: '0',
      },
      {
        label: '北京',
        value: '3',
      }],
    };
  },
  computed: {
    classVal: function () {
      return {
        'font-color-warning': true,
      };
    },
  },

  created () {
    this.loadData();
    this.enterpriseList()
  },
  methods: {
    // getContain () {
    //   const params = {
    //   };
    //   workEnterpriseAreaContainArea(params).then(res => {
    //     this.areaSelectList = res
    //     console.log(this.areaSelectList);
    //   });
    // },
    getCity (val) {

      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res

        });
      }
      else {
        this.areaSelectList = []
        this.storeList = []
        this.formInline.name = ''
        this.formInline.city = ''

      }

    },
    getStore (val) {
      workEnterpriseStoreList({
        workEnterpriseAreaCode: val,
        pageNumber: 1,
        pageSize: 1000
      }).then(res => {
        this.storeList = res.list
        console.log(this.storeList);
      });
    },
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      this.pClose(id);
    },
    pClose (id) {
      this.$refs['popover-' + id].doClose();
    },
    keywords () {
      this.pageNumber = 1;
      this.loadData();
    },
    enterpriseList (val) {
      const params = {
        keywords: val,
        pageNumber: 1,
        pageSize: 1000
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list
      });
    },
    query (val) {
      console.log(val);
      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    addEnterpriseArea () {
      this.addDialogVisible = true
      this.title = '新建门店信息'
      this.code = ''
    },
    goEdit (row) {
      this.addDialogVisible = true
      this.title = '编辑门店信息'
      this.code = row.code
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData () {
      const params = {
        ...this.formInline,
        workEnterpriseCode: this.formInline.workEnterpriseCode,
        workEnterpriseAreaCode: this.formInline.city.toString(),
        name: this.formInline.name,
        enable: this.formInline.enable,
        mobile: this.formInline.mobile,

        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      workEnterpriseStoreList(params).then(res => {
        this.list = res.list
        this.total = res.total;
        console.log(res);
      });

    },
    seach () {
      this.pageNumber = 1;
      this.loadData();
    },
    del (obj) {
      workEnterpriseStoreDel({ code: obj.code }).then(() => {
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });

    },
    addClose () {
      this.loadData();
    },
  },
};
</script>
<style>
.el-select-group__title {
  color: black;
  font-size: 15px;
  font-weight: 700;
  margin-left: -10px;
}
</style>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;

  .header_top {
    display: flex;
    align-items: center;

    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }

    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }

    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}

.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}

.table_border {
  margin: 20px 0;

  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }

  .table_demo {
    padding: 20px 0;
    font-size: 10px;
  }
}

.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}

.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
</style>
